<template>
  <label for="freight-name" class="font-bold">
    {{ t(`freight.name`) }}
  </label>
  <InputText
    id="freight-name"
    type="text"
    v-model="nameComputed"
    data-testid="freight-name"
    class="inputfield w-full mt-2"
    :class="{ 'p-invalid': val.name.$error }"
    :placeholder="t('placeholder.type', { property: t('freight.name').toLowerCase() })"
  />
  <small id="freight-name-help" data-testid="freight-name-error" class="p-error" v-if="val.name.$error">{{
    val.name.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();

const nameComputed = computed<string>({
  get: () => {
    return props.name ?? "";
  },
  set: (value) => {
    emit("update:name", value);
  },
});

const rules = {
  name: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
