<template>
  <div class="c-freight-methods" v-if="loadFailed">
    <Card>
      <template #content>
        <div>
          {{ t("freight.load-failed") }}
        </div>
      </template>
    </Card>
  </div>
  <div class="c-freight-methods" v-if="!loadFailed">
    <Card>
      <template #content>
        <div class="c-freight-list">
          <div class="mb-4 mt-2">
            <div class="table-header flex flex-col md:flex-row md:justiify-content-between justify-between flex-wrap">
              <div class="formgroup-inline">
                <span class="p-inputgroup">
                  <InputText
                    class="c-global-search-input"
                    v-model="filter['global'].value"
                    :placeholder="t('common.search')"
                    type="text"
                    data-testid="input-search-freights"
                    @focus="selectAllOnFocus"
                    autofocus
                  />
                  <span class="p-button bg-transparent border-none z-10 -ml-12"
                    ><i class="pi pi-search text-zinc-500"
                  /></span>
                </span>
              </div>

              <div class="flex mt-2 md:mt-0 justify-center">
                <div class="flex items-center justify-center">
                  <PrimeButton
                    type="button"
                    class="p-button-text p-button-sm w-full mr-4 p-2.5"
                    data-testid="add-freight-btn"
                    @click="showAddDialog = true"
                  >
                    <i class="c-plus"></i>
                    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
                  </PrimeButton>
                </div>
                <div class="flex items-center justify-center">
                  <FileUpload
                    mode="basic"
                    accept=".csv"
                    :maxFileSize="100000"
                    label="Import"
                    chooseLabel="Import"
                    class="mr-4"
                    :disabled="true"
                    :auto="true"
                  />
                </div>
                <div class="flex items-center justify-center">
                  <PrimeButton label="Export" icon="pi pi-upload" @click="exportCSV"></PrimeButton>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            :value="freightMethods"
            v-model:filters="filter"
            :autoLayout="true"
            :loading="loading"
            ref="freightMethodsTable"
            dataKey="id"
            responsiveLayout="scroll"
            selectionMode="single"
            class="c-freight-table c-compact-datatable"
            stripedRows
            sortField="name"
            :sortOrder="1"
            :paginator="true"
            :rows="25"
            data-testid="freight-table"
            @row-dblclick="onRowSelect"
          >
            <Column
              v-for="(col, index) of columns"
              :field="col.field"
              :header="col.header"
              :key="col.field + '_' + index"
              class="pb-2"
            >
            </Column>
            <Column
              field="available.forCustomers"
              :header="t(`freight.available.customers-label`)"
              :sortable="true"
              headerClass="c-freight-header"
              bodyClass="c-freight-body"
            >
              <template #body="{ data }">
                <Checkbox class="p-disabled" v-model="data.available.forCustomers" :binary="true" />
              </template>
            </Column>
            <Column
              field="available.forSuppliers"
              :header="t(`freight.available.suppliers-label`)"
              :sortable="true"
              headerClass="c-freight-header"
              bodyClass="c-freight-body"
            >
              <template #body="{ data }">
                <Checkbox class="p-disabled" v-model="data.available.forSuppliers" :binary="true" />
              </template>
            </Column>
            <Column
              field="available.forWebshop"
              :header="t('freight.available.webshop-label')"
              :sortable="true"
              headerClass="c-freight-header"
              bodyClass="c-freight-body"
            >
              <template #body="{ data }">
                <Checkbox class="p-disabled" v-model="data.available.forWebshop" :binary="true" /> </template
            ></Column>

            <Column
              :exportable="false"
              style="min-width: 8rem"
              headerClass="c-freight-header"
              bodyClass="c-freight-body"
            >
              <template #body="slotProps">
                <PrimeButton
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-text mr-2"
                  @click="editFreight(slotProps.data)"
                />
              </template>
            </Column>

            <template #empty>{{ t("freight.empty-list") }} </template>
            <template #loading>{{ t("freight.loading-list") }}</template>
          </DataTable>
        </div>
      </template>
    </Card>
  </div>

  <div class="c-service-agreements">
    <Card>
      <template #content>
        <div>
          <div class="w-full flex justify-end">
            <PrimeButton
              iconClass="c-success-button c-circular-icon"
              icon="pi pi-plus"
              class="c-circular-button"
              :label="t('freight.add-bring-service-agreement-header')"
              @click="addBringIntegrationDialogVisible = true"
              data-testid="add-bring-service-agreement-btn"
            />
            <!--TODO: Add translation for label above-->
          </div>
          <BringServiceAgreements class="mb-6" @open-update-modal="openUpdateModal" />
        </div>
      </template>
    </Card>
  </div>

  <FreightMethodAddDialog v-if="showAddDialog" v-model:showDialog="showAddDialog" />
  <FreightMethodEditDialog
    v-if="showEditDialog"
    :freightMethod="freightMethod"
    @updateFreightMethod="updateFreightMethod"
    @deleteFreightMethod="deleteFreightMethod"
    v-model:showDialog="showEditDialog"
  />

  <BringServiceAgreementAddDialog
    v-model:show-dialog="addBringIntegrationDialogVisible"
    @cancel="addBringIntegrationDialogVisible = false"
    @save="onSaveIntegration"
  />
  <BringServiceAgreementUpdateDialog
    v-if="updateBringServiceAgreementDialogVisible"
    v-model:show-dialog="updateBringServiceAgreementDialogVisible"
    :service-agreement="currentServiceAgreement"
    @save="onUpdateIntegration"
    @delete="onDeleteBringServiceAgreement"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { FilterMatchMode } from "@primevue/core/api";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useFreightMethodStore } from "@/repositories/freight-method/FreightMethodStore";
import { storeToRefs } from "pinia";
import { DataTableColumn } from "@/repositories/freight-method/model/DataTableColumn";

import FreightMethodAddDialog from "@/freight-method/add/FreightMethodAddDialog.vue";
import FreightMethodEditDialog from "@/freight-method/edit/FreightMethodEditDialog.vue";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { NewBringServiceAgreement } from "@/bring-service-agreements/model/NewBringServiceAgreement";
import { useBringServiceAgreements } from "@/bring-service-agreements/BringServiceAgreementService";
import { BringServiceAgreement } from "@/bring-service-agreements/model/BringServiceAgreement";
import BringServiceAgreementAddDialog from "@/bring-service-agreements/components/BringServiceAgreementAddDialog.vue";
import BringServiceAgreementUpdateDialog from "@/bring-service-agreements/components/BringServiceAgreementUpdateDialog.vue";
import BringServiceAgreements from "@/bring-service-agreements/components/BringServiceAgreements.vue";

const { addBringServiceAgreement, updateBringServiceAgreement, deleteBringServiceAgreement } =
  useBringServiceAgreements();

const freightMethodStore = useFreightMethodStore();
const { freightMethods, loading, loadFailed } = storeToRefs(freightMethodStore);

const toast = useCumulusToast(useToast());

const currentServiceAgreement = ref<BringServiceAgreement>(new BringServiceAgreement());

const { t } = useI18n();

const freightMethodsTable = ref();
const showAddDialog = ref(false);
const showEditDialog = ref(false);

const addBringIntegrationDialogVisible = ref(false);
const updateBringServiceAgreementDialogVisible = ref(false);

const filter = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
});

const columns = ref<DataTableColumn[]>([
  { field: "name", header: t(`freight.name`) },
  { field: "shortName", header: t("freight.short-name") },
  { field: "description", header: t("freight.company-name") },
  { field: "bring.bringServiceAgreement.name", header: t(`freight.service-agreement`) },
] as DataTableColumn[]);

const onRowSelect = (event: DataTableRowDoubleClickEvent) => editFreight(event.data);

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const onSaveIntegration = async (serviceAgreement: NewBringServiceAgreement) => {
  addBringIntegrationDialogVisible.value = false;
  await addBringServiceAgreement(serviceAgreement);
  toast.add({
    severity: "success",
    summary: t("freight.service-agreement-added"),
  });
};
const onUpdateIntegration = async (serviceAgreement: BringServiceAgreement) => {
  updateBringServiceAgreementDialogVisible.value = false;
  await updateBringServiceAgreement(serviceAgreement);
  toast.add({
    severity: "success",
    summary: t("freight.service-agreement-updated"),
  });
};

const onDeleteBringServiceAgreement = async (id: string) => {
  updateBringServiceAgreementDialogVisible.value = false;
  if (confirm(t("freight.delete-bring-service-agreement-confirm-message"))) {
    await deleteBringServiceAgreement(id);
    toast.add({
      severity: "success",
      summary: t("freight.service-agreement-deleted"),
    });
  }
};
const freightMethod = ref<FreightMethod>(new FreightMethod());

const editFreight = (value: FreightMethod) => {
  freightMethod.value = value;
  showEditDialog.value = true;
};

const findIndex = (id: string) => {
  return freightMethods.value.findIndex((freight: FreightMethod) => freight.id === id);
};

const updateFreightMethod = (freightMethod: FreightMethod) => {
  const index = findIndex(freightMethod.id);
  freightMethods.value[index] = freightMethod;
};

const deleteFreightMethod = (id: string) => {
  const index = findIndex(id);
  freightMethods.value.splice(index, 1);
};

const openUpdateModal = (serviceAgreement: BringServiceAgreement) => {
  currentServiceAgreement.value = serviceAgreement;
  updateBringServiceAgreementDialogVisible.value = true;
};
const exportCSV = () => {
  freightMethodsTable.value.exportCSV();
};

onMounted(() => {
  freightMethodStore.getAllFreightMethods();
});
</script>

<style lang="scss" scoped>
.c-freight-methods {
  margin: var(--default-content-margin);
}

.c-service-agreements {
  margin: var(--default-content-margin);
}
.c-success-button {
  background-color: var(--success-btn-bg);
}

:deep(.p-datatable .p-datatable-thead > tr > th.c-freight-header) {
  text-align: center;
}

:deep(.p-datatable .p-datatable-tbody > tr > td.c-freight-body) {
  text-align: center;
}
</style>
