<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('freight.update-bring-service-agreement-header')"
    class="w-3/12"
    :pt="{
      title: 'w-11/12 text-center',
    }"
  >
    <div class="flex flex-col">
      <label for="service-agreement-name" class="font-bold">{{ t("freight.name") }}</label>
      <InputText v-model="bringServiceAgreement.name" class="mb-2" data-testid="service-agreement-name" />
      <label for="service-agreement-customer-number" class="font-bold">{{ t("freight.customer-number") }}</label>
      <InputText
        v-model="bringServiceAgreement.customerNumber"
        class="mb-2"
        data-testid="service-agreement-customer-number"
      />
      <label for="service-agreement-client-url" class="font-bold">{{ t("freight.client-url") }}</label>
      <InputText v-model="bringServiceAgreement.clientURL" class="mb-2" data-testid="service-agreement-client-url" />
      <label for="service-agreement-uid" class="font-bold">{{ t("freight.my-bring-api-uid") }}</label>
      <InputText
        v-model="bringServiceAgreement.myBringApiUid"
        class="mb-2"
        data-testid="service-agreement-my-bring-api-uid"
      />
      <label for="service-agreement-api-key" class="font-bold">{{ t("freight.my-bring-api-key") }}</label>
      <InputText
        v-model="bringServiceAgreement.myBringApiKey"
        class="mb-2"
        data-testid="service-agreement-my-bring-api-key"
      />
    </div>
    <div class="flex justify-between">
      <PrimeButton severity="warning" :label="t('common.cancel')" @click="emit('cancel')" />
      <PrimeButton
        severity="success"
        :label="t('common.save')"
        @click="emit('save', bringServiceAgreement)"
        data-testid="save-agreement-btn"
      />

      <PrimeButton
        severity="danger"
        :label="t('common.delete')"
        @click="emit('delete', bringServiceAgreement.id)"
        data-testid="delete-agreement-btn"
      />
    </div>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import clonedeep from "lodash.clonedeep";
import { BringServiceAgreement } from "../model/BringServiceAgreement";

const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
  serviceAgreement: BringServiceAgreement;
}>();

const bringServiceAgreement = ref<BringServiceAgreement>(clonedeep(props.serviceAgreement));

const visible = computed({
  get: () => props.showDialog,
  set: (value) => {
    emit("update:showDialog", value);
  },
});

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "save", value: BringServiceAgreement): void;
  (e: "delete", value: string): void;
  (e: "update:showDialog", value: boolean): void;
}>();
</script>
