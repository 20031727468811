<template>
  <label class="font-bold">{{ t(`freight.available.label`) }}</label>
  <div class="field-checkbox">
    <Checkbox inputId="for-customers" v-model="forCustomersComputed" :binary="true" data-testid="is-for-customers" />
    <label for="for-customers" data-testid="for-customers" class="font-bold ml-2">
      {{ t(`freight.available.customers-label`) }}
    </label>
  </div>

  <div class="field-checkbox mt-2">
    <Checkbox inputId="for-suppliers" v-model="forSuppliersComputed" :binary="true" data-testid="is-for-suppliers" />
    <label for="for-suppliers" data-testid="for-suppliers" class="font-bold ml-2">
      {{ t(`freight.available.suppliers-label`) }}
    </label>
  </div>

  <div class="field-checkbox mt-2">
    <Checkbox inputId="for-webshop" v-model="forWebshopComputed" :binary="true" data-testid="is-for-webshop" />
    <label for="for-webshop" data-testid="for-webshop" class="font-bold ml-2">
      {{ t(`freight.available.webshop-label`) }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  forCustomers: boolean;
  forSuppliers: boolean;
  forWebshop: boolean;
}>();

const emit = defineEmits<{
  (e: "update:forCustomers", value: boolean): void;
  (e: "update:forSuppliers", value: boolean): void;
  (e: "update:forWebshop", value: boolean): void;
}>();

const forCustomersComputed = computed<boolean>({
  get: () => {
    return props.forCustomers ?? false;
  },
  set: (value) => {
    emit("update:forCustomers", value);
  },
});

const forSuppliersComputed = computed<boolean>({
  get: () => {
    return props.forSuppliers ?? false;
  },
  set: (value) => {
    emit("update:forSuppliers", value);
  },
});

const forWebshopComputed = computed<boolean>({
  get: () => {
    return props.forWebshop ?? false;
  },
  set: (value) => {
    emit("update:forWebshop", value);
  },
});
</script>

<style lang="scss" scoped>
.p-error {
  position: absolute;
  margin-top: 2rem;
}
</style>
