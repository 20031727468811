<template>
  <label for="freight-short-name" class="font-bold mb">
    {{ t(`freight.short-name`) }}
  </label>
  <InputText
    id="freight-short-name"
    type="text"
    v-model="shortNameComputed"
    data-testid="freight-short-name"
    class="inputfield w-full mt-2"
    :class="{ 'p-invalid': val.shortName.$error }"
    :placeholder="t('placeholder.type', { property: t('freight.short-name').toLowerCase() })"
  />
  <small
    id="freight-short-name-help"
    data-testid="freight-short-name-error"
    class="p-error"
    v-if="val.shortName.$error"
    >{{ val.shortName.$errors[0].$message }}</small
  >
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  shortName: string;
}>();

const emit = defineEmits<{
  (e: "update:shortName", value: string): void;
}>();

const shortNameComputed = computed<string>({
  get: () => {
    return props.shortName ?? "";
  },
  set: (value) => {
    emit("update:shortName", value);
  },
});

const rules = {
  shortName: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
