<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t(`freight.header-add`)"
    :modal="true"
    class="c-freight-dialog"
    :pt="{
      title: 'w-11/12 text-center',
    }"
  >
    <div class="grid grid-cols-12 gap-4 mt-2">
      <div class="flex col-span-12 md:col-span-7">
        <div class="formgrid grid grid-cols-12 gap-4">
          <div class="field col-span-12">
            <Name v-model:name="freightMethod.name" />
          </div>
          <div class="field col-span-12 my-2">
            <Carrier v-model:description="freightMethod.description" />
          </div>
          <div class="field col-span-12 w-full mt-3.5">
            <BringServiceAgreement v-model:bring="freightMethod.bring" />
          </div>
        </div>
      </div>
      <div class="flex col-span-12 md:col-span-5">
        <div class="formgrid grid grid-cols-12 gap-4">
          <div class="field col-span-12 mb-1">
            <ShortName v-model:shortName="freightMethod.shortName" />
          </div>
          <div class="field col-span-12 mb-1">
            <Available
              v-model:forCustomers="freightMethod.available.forCustomers"
              v-model:forSuppliers="freightMethod.available.forSuppliers"
              v-model:forWebshop="freightMethod.available.forWebshop"
            />
          </div>
          <div class="field col-span-12">
            <Tax v-model:tax="freightMethod.tax" @setInitialTax="setInitialTax" />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container">
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="onCancel"
            severity="cancel"
            text
            :pt="{
              label: 'font-bold -mx-6',
            }"
            icon="pi pi-times"
          />
          <PrimeDialog
            data-testid="unsaved-changes-dialog"
            v-model:visible="unsavedChangesDialogVisible"
            :header="t('common.unsaved-changes-header')"
            :style="{ width: '25vw' }"
            position="center"
            :modal="true"
          >
            <p class="p-6">
              {{ t("common.unsaved-changes-text") }}
            </p>
            <template #footer>
              <PrimeButton
                :label="t('common.cancel')"
                @click="unsavedChangesDialogVisible = false"
                severity="cancel"
                text
                :pt="{
                  label: 'font-bold -mx-6',
                }"
                icon="pi pi-times"
              />
              <PrimeButton :label="t('common.ignore')" @click="visible = false" text />
              <PrimeButton
                :label="t('common.save')"
                icon="pi pi-check"
                @click="onCreateFreightMethod"
                :pt="{
                  label: '-mx-4',
                }"
              />
            </template>
          </PrimeDialog>
        </div>
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.add`)"
            data-testid="create-btn"
            @click="onCreateFreightMethod"
            :disabled="saving"
            icon="pi pi-check"
            :pt="{
              label: '-mx-4',
            }"
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import isEqual from "lodash.isequal";
import useValidate from "@vuelidate/core";
import Name from "../components/Name.vue";
import ShortName from "../components/ShortName.vue";
import Carrier from "../components/Carrier.vue";
import Available from "../components/Available.vue";
import Tax from "../components/Tax.vue";

import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useFreightMethodStore } from "@/repositories/freight-method/FreightMethodStore";
import { storeToRefs } from "pinia";

import { FreightMethodTax } from "@/repositories/freight-method/model/FreightMethodTax";
import BringServiceAgreement from "@/bring-service-agreements/components/BringServiceAgreement.vue";

const freightMethodStore = useFreightMethodStore();
const freightMethod = ref(new FreightMethod());
const initialFreightMethod = ref<FreightMethod>(new FreightMethod());
const unsavedChangesDialogVisible = ref();
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

const { saving } = storeToRefs(freightMethodStore);
const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

//Nested validation
const val = useValidate();
const toast = useCumulusToast(useToast());

const onCreateFreightMethod = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  await freightMethodStore.addFreightMethod(freightMethod.value);

  toast.add({
    severity: "success",
    summary: t("freight.toast.add-success.summary"),
    detail: t("freight.toast.add-success.detail", { name: freightMethod.value.name }),
  });

  visible.value = false;
};

const setInitialTax = (tax: FreightMethodTax) => {
  initialFreightMethod.value.tax = tax;
  freightMethod.value.tax = tax;
};

const hasUpdates = computed(() => {
  return !isEqual(freightMethod.value, initialFreightMethod.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    visible.value = false;
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    if (unsavedChangesDialogVisible.value) {
      unsavedChangesDialogVisible.value = false;
      previouslyFocusedInput.value?.focus();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    visible.value = false;
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});
</script>

<style lang="scss">
.c-freight-dialog {
  width: 100vw;

  @media screen and (min-width: 992px) {
    width: 60vw;
    max-width: 50rem;
  }
}
</style>
