<template>
  <label for="freight-description" class="font-bold">
    {{ t(`freight.description`) }}
  </label>
  <InputText
    id="freight-description"
    type="text"
    v-model="descriptionComputed"
    data-testid="freight-description"
    class="inputfield w-full mt-2"
    :class="{ 'p-invalid': val.description.$error }"
    :placeholder="t('placeholder.type', { property: t('freight.description').toLowerCase() })"
  />
  <small
    id="freight-description-help"
    data-testid="freight-description-error"
    class="p-error"
    v-if="val.description.$error"
    >{{ val.description.$errors[0].$message }}</small
  >
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  description: string;
}>();

const emit = defineEmits<{
  (e: "update:description", value: string): void;
}>();

const descriptionComputed = computed<string>({
  get: () => {
    return props.description ?? "";
  },
  set: (value) => {
    emit("update:description", value);
  },
});

const rules = {
  description: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
